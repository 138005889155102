define('form_validation',[
	'jquery',
	'plugins/validator/jquery.validator',
	'css!js-css/formvalidator'
], function($) {
	return {
		initialize: function(callback) {
			$(callback);
		}
	};
});

