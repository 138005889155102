"use strict";

require(['dcf-utility'], function (utilityModule) {
  // Trigger dcf-webp class processing
  utilityModule.DCFUtility.flagSupportsWebP();

  // Trigger dcf-no-js check
  utilityModule.DCFUtility.flagSupportsJavaScript();
});

define("flags", function(){});

