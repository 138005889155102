"use strict";

define('cta-nav',[], function () {
  var initialized = false;
  var Plugin = {
    initialize: function initialize() {
      if (initialized) {
        return;
      }
      var ctaLinks = document.querySelectorAll('.dcf-link-cta');
      var ctaButtons = document.querySelectorAll('.dcf-btn-toggle-cta');
      if (window.matchMedia('(min-width: 56.12em)').matches) {
        // Remove fallback links
        for (var i = 0; i < ctaLinks.length; i++) {
          var ctaLink = ctaLinks[i];
          ctaLink.setAttribute('hidden', '');
        }

        // Show buttons (instead of fallback links) to toggle display of options popovers
        for (var _i = 0; _i < ctaButtons.length; _i++) {
          var ctaButton = ctaButtons[_i];
          ctaButton.removeAttribute('hidden');
        }
      }
    }
  };
  return Plugin;
});

