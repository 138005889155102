"use strict";

require(['dcf-popup', 'css!js-css/popups'], function (DCFPopup) {
  // Get all the popups on the dom
  var popups = document.querySelectorAll('.dcf-popup');

  // Initialize the popup theme
  var popupTheme = new DCFPopup.DCFPopupTheme();

  // Any changes to the theme would go here

  // Initialize the popup with the modified theme
  var popupObj = new DCFPopup.DCFPopup(popups, popupTheme);
  popupObj.initialize();
});

define("popups", function(){});

